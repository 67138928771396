import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import { isFunction } from "lodash";

import * as pool from "../mediaElementPool";

import { AttachTiming } from "../hooks/useTiming";

import "./StreamingVideo.scss";

export interface StreamingVideoProps {
  src: string;
  targetLevel: number | "auto";
  className?: string;
  controls?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  usePool?: boolean;
  attachTiming?: AttachTiming;
  loop: boolean;
  onCanPlay?: () => void;
  onLevelChanged?: (level: number) => void;
}
export const StreamingVideo = React.forwardRef<
  { el: HTMLVideoElement; play: () => void },
  StreamingVideoProps
>(
  (
    {
      src,
      targetLevel,
      className,
      controls,
      autoplay,
      muted,
      loop,
      usePool,
      attachTiming,
      onCanPlay,
      onLevelChanged,
    },
    ref
  ) => {
    let siblingRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      let video = usePool
        ? pool.checkoutVideo()
        : document.createElement("video");
      if (className) video.classList.add(className);
      video.controls = !!controls;
      video.muted = !!muted;
      video.loop = loop;
      video.playsInline = true;
      siblingRef.current!.parentElement!.insertBefore(
        video,
        siblingRef.current!
      );

      video.addEventListener("canplaythrough", () => onCanPlay?.(), {
        once: true,
      });

      if (Hls.isSupported()) {
        let hls = new Hls({
          capLevelOnFPSDrop: true,
          // debug: true,
          liveSyncDurationCount: 2,
          initialLiveManifestSize: 2,
          liveMaxLatencyDurationCount: 3,
        });
        if (targetLevel !== "auto") {
          hls.currentLevel = targetLevel;
        }
        hls.on(Hls.Events.LEVEL_SWITCHED, () => {
          onLevelChanged?.(hls.currentLevel);
        });

        hls.loadSource(src);
        hls.attachMedia(video);
        let detachTiming = attachTiming?.(video);

        let play = () => {
          video.play();
        };

        if (autoplay) {
          play();
        } else {
          if (isFunction(ref)) {
            ref({ el: video, play });
          } else if (ref) {
            ref.current = { el: video, play };
          }
        }

        return () => {
          video.pause();
          detachTiming?.();
          hls.destroy();
          video.remove();
          if (className) video.classList.add(className);
          if (usePool) {
            pool.checkinVideo(video);
          }
        };
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src;
        video.load();
        if (autoplay) {
          video.play();
        }
        let detachTiming = attachTiming?.(video);
        return () => {
          video.pause();
          detachTiming?.();
          video.src = "";
          video.remove();
          if (className) video.classList.add(className);
          if (usePool) {
            pool.checkinVideo(video);
          }
        };
      }
    }, [
      src,
      autoplay,
      controls,
      muted,
      loop,
      className,
      targetLevel,
      onCanPlay,
      onLevelChanged,
      usePool,
      attachTiming,
      ref,
    ]);

    return <div style={{ display: "none" }} ref={siblingRef} />;
  }
);
