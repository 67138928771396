import React from "react";
import "./Index.scss";

import Logo from "../assets/images/logo.svg";
import ArtsCouncilLogo from "../assets/images/artsCouncilEngland.png";


export const Index: React.FC = () => {
  return (
    <div className="landingSite">
      <header className="header" id="header">
          <div className="headerContent">
            <h1 className="logo"><img className="logoImage" src={Logo} alt="Daydream" /></h1>
            <p className="introText">An interactive online film experience<br/> exploring daydream states by Amy Croft</p>
            <div className="splashFooter">
              <p className="comingSoon">Coming soon</p>
              <a className="artsCouncilLink" href="https://www.artscouncil.org.uk/" target="_blank" rel="noreferrer"><img className="artsCouncilLogo" src={ArtsCouncilLogo} alt="Supported using public funding by Arts Council England" /></a>
            </div>
          </div>
      </header>
    </div>
  );
};
