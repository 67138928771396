import silentAudioUrl from "./assets/film/silence.mp3";
import blankVideoUrl from "./assets/film/blank.mp4";
import { audioContext } from "./audio";

export type AudioSource = {
  element: HTMLAudioElement;
  source: MediaElementAudioSourceNode;
};
const audioPool: AudioSource[] = [];
const videoPool: HTMLVideoElement[] = [];

export const initPool = ({
  audioPoolSize = 9,
  videoPoolSize = 3,
}: { audioPoolSize?: number; videoPoolSize?: number } = {}) => {
  let promises: Promise<void>[] = [];
  for (let i = 0; i < audioPoolSize; i++) {
    let element = new Audio();
    element.src = silentAudioUrl;
    promises.push(element.play());
    let source = audioContext.createMediaElementSource(element);
    audioPool.push({ element, source });
  }
  for (let i = 0; i < videoPoolSize; i++) {
    let video = document.createElement("video");
    video.playsInline = true;
    video.src = blankVideoUrl;
    promises.push(video.play());
    videoPool.push(video);
  }
  return Promise.all(promises);
};

export function checkoutAudio() {
  if (audioPool.length > 0) {
    console.log("checking audio out from pool");
    return audioPool.shift()!;
  } else {
    throw new Error("No audio elements left in pool");
  }
}

export function checkinAudio(source: AudioSource) {
  console.log("checking audio in to pool");
  source.element.src = "";
  source.element.load();
  audioPool.push(source);
}

export function checkoutVideo() {
  if (videoPool.length > 0) {
    console.log("checking video out from pool");
    return videoPool.shift()!;
  } else {
    throw new Error("No video elements left in pool");
  }
}

export function checkinVideo(video: HTMLVideoElement) {
  console.log("checking video in to pool");
  video.src = "";
  video.load();
  videoPool.push(video);
}
