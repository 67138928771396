import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import ScrollIntoView from "react-scroll-into-view";
import "./FullSite.scss";

import Logo from "../assets/images/logo.svg";
import ArtsCouncilLogo from "../assets/images/artsCouncilEngland.png";
import LeverhulmeTrustLogo from "../assets/images/leverhulmeTrust.png";
import ArrowImage from "../assets/images/arrow.svg";

export const FullSite: React.FC = () => {
  const { ref: mainRef, inView: mainInView } = useInView({
    threshold: 0.01,
  });
  const { ref: aboutRef, inView: aboutInView } = useInView({
    threshold: 0.5,
  });
  const { ref: viewRef, inView: viewInView } = useInView({
    threshold: 0.5,
  });
  const { ref: contactRef, inView: contactInView } = useInView({
    threshold: 0.5,
  });
  const { ref: creditsRef, inView: creditsInView } = useInView({
    threshold: 0.5,
  });

  let [bookingState, setBookingState] = useState<
    "notSent" | "sent" | "failed"
  >();
  let [bookingName, setBookingName] = useState("");
  let [bookingEmail, setBookingEmail] = useState("");
  let [bookingContactPref, setBookingContactPref] = useState(false);

  let onSubmitBooking = (evt: React.FormEvent) => {
    evt.preventDefault();
    fetch("/.netlify/functions/createBooking", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: bookingName,
        email: bookingEmail,
        startTime: Date.now(),
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setBookingState("sent");
        } else {
          setBookingState("failed");
        }
      })
      .catch((err) => {
        console.error(err);
        setBookingState("failed");
      });
  };

  return (
    <div className="fullSite">
      <nav className={classNames("nav", mainInView ? "isVisible" : "")}>
        <ul className="navList">
          <li className="navListItem">
            <ScrollIntoView selector="#about">
              <button
                className={classNames("navButton", "navButton--about", {
                  isCurrent: aboutInView,
                })}
              >
                About
              </button>
            </ScrollIntoView>
          </li>
          <li className="navListItem">
            <ScrollIntoView selector="#view">
              <button
                className={classNames("navButton", "navButton--view", {
                  isCurrent: viewInView,
                })}
              >
                View
              </button>
            </ScrollIntoView>
          </li>
          <li className="navListItem">
            <ScrollIntoView selector="#contact">
              <button
                className={classNames("navButton", "navButton--contact", {
                  isCurrent: contactInView,
                })}
              >
                Contact
              </button>
            </ScrollIntoView>
          </li>
          <li className="navListItem">
            <ScrollIntoView selector="#credits">
              <button
                className={classNames("navButton", "navButton--credits", {
                  isCurrent: creditsInView,
                })}
              >
                Credits
              </button>
            </ScrollIntoView>
          </li>
        </ul>
      </nav>
      <header className="header" id="header">
        <div className="headerContent">
          <h1 className="logo">
            <img className="logoImage" src={Logo} alt="Daydream" />
          </h1>
          <p className="introText">
            An interactive online film experience
            <br /> exploring daydream states by Amy Croft
          </p>
          <div className="splashFooter">
            <div className="splashFooterFirst">
              <img
                className="artsCouncilLogo"
                src={ArtsCouncilLogo}
                alt="Supported using public funding by Arts Council England"
              />
            </div>
            <div className="splashFooterSecond">
              <img
                className="arrow"
                src={ArrowImage}
                alt="Arrow pointing down"
              />
            </div>
          </div>
        </div>
      </header>
      <main className="main" ref={mainRef}>
        <section className="section section--about" id="about" ref={aboutRef}>
          <div className="columns">
            <p>
              ‘Daydream’ is a new project that focuses on the methods and
              potential applications of the science community’s research into
              daydreaming.
            </p>
            <p>
              Utilising your home computer’s webcam and eye tracking technology,
              the work explores relationships between the corporeal and the
              digital. A series of non-linear narratives become interwoven in
              the work, each shifting the levels of individual agency and
              external observation of this ubiquitous yet fleeting activity of
              daydreaming. How these experiences blend together becomes
              individual to each viewer’s own state of attention or drifting
              mind wanderings which may occur while watching the film.
            </p>
            <p>
              Alongside referencing contemporary and historical studies of
              daydream, the film offers alternative scenarios that experiment
              with kinesthetic memory and the embodiment of our daydreams by
              both watching the movement of others while daydreaming, but also
              in the digital body of the film itself -the camera’s movement, the
              scene cut, the audio environment.
            </p>
            <blockquote>
              “As the design of technologies relentlessly compete to hold and
              direct our attention, I wonder if our bodies could lead our minds
              into a social sensorium of daydream, a shared metaverse of mind
              wanderings that converge autobiographical memories and run
              simulations of future possibilities?”
            </blockquote>
            <p>
              ‘Daydream’ is the culmination of a body of research and events
              supported by the Leverhulme Trust residency program at The
              Interactive Architecture Lab, The Bartlett School of Architecture,
              University College London; and Arts Council England.
            </p>
            <p>
              Find out more about the artist’s practice:{" "}
              <a href="//www.amycroft.co.uk" target="_blank" rel="noreferrer">
                www.amycroft.co.uk
              </a>
            </p>
          </div>
        </section>
        <section className="section section--view" id="view" ref={viewRef}>
          <div className="book">
            <h2>Book</h2>
            <p>Daydream is a free online film which lasts between 20-30 min.</p>
            <p>
              Please read carefully the system requirements and viewing advice
              before you begin the experience.
            </p>
            <p>
              To access the film complete the form below and you will be sent by
              email a unique link which will be valid for 24hrs.
            </p>
            <p>Simply click on the link in the email to begin.</p>
            {bookingState !== "sent" && (
              <>
                {bookingState === "failed" && (
                  <p>
                    Sorry, something went wrong when making your booking. Please
                    try again.
                  </p>
                )}
                <form className="form form--booking" onSubmit={onSubmitBooking}>
                  <div className="inputGroup">
                    <label>Name</label>
                    <input
                      type="text"
                      value={bookingName}
                      onChange={(evt) => setBookingName(evt.target.value)}
                    ></input>
                  </div>
                  <div className="inputGroup">
                    <label>Email</label>
                    <input
                      type="email"
                      value={bookingEmail}
                      onChange={(evt) => setBookingEmail(evt.target.value)}
                    ></input>
                  </div>
                  <div className="inputGroup">
                    <label>Contact Preferences</label>
                    <span className="checkboxWrapper">
                      <input
                        type="checkbox"
                        checked={bookingContactPref}
                        onChange={(e) =>
                          setBookingContactPref(e.target.checked)
                        }
                      />
                      <label>
                        I would like to receive emails about future events and
                        exhibitions
                      </label>
                    </span>
                  </div>
                  <button
                    type="submit"
                    disabled={
                      bookingName.length === 0 || bookingEmail.length === 0
                    }
                  >
                    Book here
                  </button>
                </form>
              </>
            )}
            {bookingState === "sent" && (
              <p>
                Thank you! Please check your inbox for an email with your unique
                viewing link.
              </p>
            )}
          </div>
          <div className="requirements">
            <h2>System requirements</h2>
            <ul>
              <li>
                An internet speed of 5mbps or more (
                <a
                  href="//speed.cloudflare.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Check your internet speed here
                </a>
                )
              </li>
              <li>A modern computer or iPad</li>
              <li>‘Daydream’ will not function on a mobile device.</li>
            </ul>
            <h2>Viewing advice</h2>
            <p>
              To best experience ‘Daydream’ we advise you to find a quiet spot
              to view the work alone and undisturbed.
            </p>
            <p>
              Choose a comfortable relaxed position in front of your screen
              where you will be happy to stay for the duration of the film.
            </p>
            <p>The film lasts between 20-30 minutes.</p>
            <p>
              It is intended to be experienced with a good pair of headphones
              and viewed using a computer screen or tablet.
            </p>
            <p>
              Daydream responds to your individual engagement with the work and
              requires access to your webcam. Please accept the request to
              access your webcam in the browser. None of your personal data is
              stored while using this website. For more details see the{" "}
              <Link to="/privacy" className="button button--inline">
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </section>
        <section
          className="section section--contact"
          id="contact"
          ref={contactRef}
        >
          <div className="contact">
            <h2>Contact</h2>
            <form
              className="form form--contact"
              name="Contact"
              method="POST"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="Contact" />
              <div className="inputGroup">
                <label>Name</label>
                <input type="text" name="Name"></input>
              </div>
              <div className="inputGroup">
                <label>Email</label>
                <input type="email" name="Email"></input>
              </div>
              <div className="inputGroup">
                <label>Message</label>
                <textarea rows={5} name="Message"></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </section>
        <section
          className="section section--credits"
          id="credits"
          ref={creditsRef}
        >
          <div className="columns">
            <h2>
              Daydream
              <br /> An Artwork by Amy Croft
            </h2>
            <ul className="creditList">
              <li>System Design and Development: Counterpoint</li>
              <li>3D Visuals and Artwork: Counterpoint and Amy Croft</li>
              <li>Sound Design: Team Suzuki at Pentagram</li>
              <li>Cinematography: Alberto Balazs</li>
              <li>Graphic Design: Zoë Anspach</li>
              <li>Logotype: Self Modern by Bretagne Type foundry</li>
            </ul>
            <h3>Movement Workshop ‘Present in your Absence’</h3>
            <ul className="creditList">
              <li>Workshop Development: Gabrielle Moleta and Amy Croft</li>
              <li>
                Filmed on Location: Italia Conti Academy of Theatre Arts, London
              </li>
              <li>Sound Recordist: Doug Haywood</li>
              <li>Assistant Sound Recordist: Ali Zulfqar</li>
              <li>Workshop Leader: Gabrielle Moleta</li>
              <li>
                Workshop Students: Georgie Fox, Patrick Medway, Amy Kitts,
                <br /> Gus Gordon, Magdalena Jasiniak, Gary Duncan, Kate
                Donnachie,
                <br /> Matthew Gouldesbrough
              </li>
            </ul>
            <h3>Daydream Experiment ‘Grammar in the Shimmer’</h3>
            <ul className="creditList">
              <li>Experiment Design: Amy Croft</li>
              <li>Filmed on Location: UCL Bartlett</li>
              <li>
                Inspired by Daydream Experiments: Paul Seli & Jonathan Carriere,
                et al.{" "}
                <em>
                  Restless Mind, Restless Body. Journal of experimental
                  psychology. Learning, memory, and cognition
                </em>
                . 2013
              </li>
              <li>Hand Tracking System Development: Casey Mahon</li>
              <li>Key Grip: Luis F Rodriguez-Garcia</li>
            </ul>
            <ul className="creditList">
              <li>Cast</li>
              <li>Investigators: Michael J Harding, Tanja Hillbrink</li>
              <li>Daydreamers: Nadja Lavin, Helena Lavin, Bened</li>
            </ul>
            <ul className="creditList">
              <li>With thanks to:</li>
              <li>
                Vitra; UCL Bartlett; Felicity Callard, Professor in Human
                Geography, University of Glasgow; Jarvia Foxter; Ruairi Glynn,
                Associate Professor, The Bartlett School of Architecture; Peter
                Kadlot; Alfredo J López Nieve; Paul Seli, Assistant Professor of
                Psychology and Neuroscience at Duke University; Yuri Suzuki,
                Pentagram; Pierre Tardif.
              </li>
            </ul>
            <p>Copyright 2021</p>
            <p>Supported by:</p>
            <img
              className="artsCouncilLogo"
              src={ArtsCouncilLogo}
              alt="Supported using public funding by Arts Council England"
            />
            <img
              className="leverhulmeTrustLogo"
              src={LeverhulmeTrustLogo}
              alt="Supported using public funding by Arts Council England"
            />
          </div>
        </section>
      </main>
      <footer className="footer">
        <Link to="/privacy" className="button button--inline">
          Privacy Policy
        </Link>
        <p className="copyrightText">
          &copy; Copyright 2021 - All Rights Reserved
        </p>
      </footer>
    </div>
  );
};
