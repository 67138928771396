import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Index } from "./landingsite/Index";
import { Privacy } from "./landingsite/Privacy";
import { FullSite } from "./landingsite/FullSite";
import { LoadingIndicator } from "./components/LoadingIndicator";
import { StreamingVideo } from "./components/StreamingVideo";

const Film = lazy(() => import("./Film"));

export const App: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route path="/privacy/">
            <Privacy />
          </Route>
          <Route path="/fullsite/">
            <FullSite />
          </Route>
          <Route path="/film/:viewingToken">
            <Film />
          </Route>
        </Switch>
        <Route exact path={["/", "/fullsite", "/privacy"]}>
          <StreamingVideo
            className="backgroundVideo"
            src="https://cloudflarestream.com/bd77f7fed88948b448398a632ed35dcc/manifest/video.m3u8"
            targetLevel="auto"
            autoplay
            muted
            loop
          />
        </Route>
      </Suspense>
    </Router>
  );
};
